.header-footer-border {
  background: var(
    --1CH-Gradient,
    linear-gradient(
      90deg,
      #54c9eb 0%,
      #f68522 33.33%,
      #78c143 67.19%,
      #b8832c 100%
    )
  );
  height: 19px;
}
.display-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.frame-w100 {
  width: auto !important;
}
.w100 {
  width: 100%;
}
.w-img {
  width:5rem;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.banner-left-frame {
  top: 0rem;
  left: 0rem;
}
.text-center {
  text-align: center;
}
.banner-right-frame {
  top: 0rem;
  right: 0rem;
}
.banner-text {
  color: #3b3b3b;
  font-size: 64px;
  font-weight: 600;
}
.banner-text-gradient {
  background: var(
    --1CH-Gradient,
    linear-gradient(
      90deg,
      #54c9eb 4.64%,
      #f68522 36.92%,
      #78c043 68.21%,
      #b8832c 99.99%
    )
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.v-line {
  width: 1.396px;
  height: 47.469px;
  background: #d2d2d2;
  margin: 0rem 1rem;
}
.btn-web {
  color: #fff;
  height: auto;
  font-size: 19px;
  padding: 9px 35px;
  border: none;
  border-radius: 12px;
  background: var(
    --Orange-1CH,
    linear-gradient(180deg, #f68522 0%, #d26403 100%)
  );
  box-shadow: 3.175px 5.714px 15.238px 0px rgba(0, 0, 0, 0.03);
}
.btn-web:hover {
  color: #fff;
  height: auto;
  font-size: 19px;
  padding: 9px 35px;
  border: none;
  border-radius: 12px;
  background: var(
    --Orange-1CH,
    linear-gradient(180deg, #f68522 0%, #d26403 100%)
  ) !important;
  box-shadow: 3.175px 5.714px 15.238px 0px rgba(0, 0, 0, 0.03);
}
.about-content {
  color: #3b3b3b;
  font-family: Poppins;
  font-size: 23.483px;
  font-style: normal;
  font-weight: 500;
  line-height: 147%;
  letter-spacing: -0.47px;
  text-align: justify;
}
.event-details {
  color: #fff;
  font-size: 27.047px;
  font-weight: 600;
  border-radius: 22.776px;
  background: linear-gradient(
    180deg,
    #82bc41 0%,
    #5b8d22 156.31%,
    #6fab2c 156.31%
  );
  padding: 4.4rem 2rem;
}
ul {
  list-style-type: none;
}
.mr-3 {
  margin-right: 1.3rem;
}
.lion-banner-start1 {
  top: -2rem;
  right: -2rem;
}
.lion-banner-start2 {
  top: -2rem;
  right: -2rem;
}

.join-us-line {
  background: #d2d2d2;
  width: 100%;
  height: 1.225px;
}
.join-us-poisition {
  top: 1.8rem;
}
.line-bg {
  width: 89vw;
}
.custom-placeholder-color {
  width: 100%;
  border-bottom: 1px solid #1e1e1e;
  border-top: none;
  border-radius: 0;
  border-right: none;
  border-left: none;
  padding: 0.8rem;
}
.custom-placeholder-color:hover {
  width: 100%;
  border-bottom: 1px solid #1e1e1e;
  border-top: none;
  border-radius: 0;
  border-right: none;
  border-left: none;
  padding: 0.8rem;
}
.custom-placeholder-color::placeholder {
  color: rgba(8, 8, 8, 0.46) !important; /* Change this to your desired color */
}
.registerf-title {
  color: #3b3b3b;
  font-size: 43.92px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.878px;
}
.org-bg {
  background-color: #ff8112;
}
.box-outer {
  border-radius: 1rem;
  padding: 3rem 0rem;
  height: 15rem;
}
.box-title {
  color: #fff;
  font-size: 20.629px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.box-content {
  color: #fff;
  font-size: 14.208px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.blue-bg {
  background-color: #1eb5e0;
}
.green-bg {
  background-color: rgba(82, 176, 11, 0.99);
}
.orange-bg {
  background-color: #ca710e;
}
.pink-bg {
  background-color: #cb30c6;
}
.lite-blue-bg {
  background-color: #7c84e8;
}
.gray-bg {
  background-color: #808285;
}
.peach-bg {
  background-color: #f1aeb5;
}
.dark-blue-bg {
  background-color: #0866fe;
}
.border-box {
  border-radius: 12.216px;
  padding: 3rem 4rem;
}
.border-green {
  border: 1.879px solid #81bd41;
}
.border-darkblue {
  border: 1.879px solid #0866fe;
}
.border-peach {
  border: 1.879px solid #f1aeb5;
}
.border-orange {
  border: 1.879px solid #ca710e;
}
.border-lite-blue {
  border: 1.879px solid #7c84e8;
}
.border-pink {
  border: 1.879px solid #cb30c6;
}
.border-gray {
  border: 1.879px solid #6b6b6b;
}
.border-box-left-title {
  color: #3b3b3b;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  padding-right: 3rem;
}
.border-box-content {
  color: #3b3b3b;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;
  border-left: 1px solid #d2d2d2;
  padding-left: 3rem;
}
.content-bold {
  color: #3b3b3b;
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}
.agenda-time {
  position: absolute;
  color: #fff;
  font-size: 18.794px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 6.578px;
  padding: 0.3rem 1rem;
  top: -1.2rem;
  left: 3rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.border-box-recommended {
  color: #3b3b3b;
  text-align: justify;
  font-family: Poppins;
  font-size: 13.899px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.333%;
  letter-spacing: -0.278px;
  border-radius: 5.661px;
  background: rgba(72, 72, 72, 0.15);
  padding: 0.5rem 0.5rem;
}
.post-footer-content {
  color: #505154;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 120.268%; /* 31.27px */
}
.post-footer-content {
  margin: 4rem 1rem;
  color: #505154;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 120.268%;
}
.footer-content {
  color: #505154;
  text-align: justify;
  font-size: 12.906px;
  font-style: normal;
  font-weight: 400;
  line-height: 96%; /* 12.39px */
  margin-bottom: 3rem;
}

.eight h1 {
  font-size: 53.915px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.078px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 40px 0;
  grid-gap: 22px;
}
.text-uppercase {
  text-transform: uppercase;
}
.line-banner-text-center {
  text-align: center;
  border-radius: 24px;
  border: 4px solid #7db63d;
  width: 65%;
  font-size: 21px;
  font-weight: 700;
  color: #7db63d;
  padding: 7px 6px;
  position: absolute;
  background: #fff;
  top: -28px;
  left: 103px;
}

.eight h1:after,
.eight h1:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #ccc;
}

@media screen and (max-width: 426px) {
  .frame-w100 {
    width: 100% !important;
  }
  .banner-text {
    font-size: 46px;
  }
  .v-line {
    width: 1.396px;
    height: 47.469px;
    background: #d2d2d2;
    margin: 0rem 0rem;
  }
  .lion-banner-start1 {
    top: 0rem;
    right: 0rem;
  }
  .lion-banner-start2 {
    top: 0rem;
    right: 0rem;
  }
  .border-box-content {
    border-left: none;
    padding-left: 0rem;
  }
  .border-box-left-title {
    padding-right: 0rem;
  }
  .eight h1 {
    font-size: 26.915px;
    grid-template-rows: 20px 0;
  }
  .line-banner-text-center {
    text-align: center;
    border-radius: 24px;
    border: 4px solid #7db63d;
    width: 82%;
    font-size: 21px;
    font-weight: 700;
    color: #7db63d;
    padding: 7px 6px;
    position: absolute;
    background: #fff;
    top: -52px;
    left: 23px;
  }
}
